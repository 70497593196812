







































































import APISTATE from '@/enums/APISTATE';
import { ProjectsDto } from '@/models/planning/ProjectsDto';
import { SchoolTermDto } from '@/models/planning/SchoolTermDto';
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'projects-view',
  data: () => ({
    filterDepartment: null,
    filterSchoolTerms: null,

  }),
  async mounted() {
    this.updatePageTitle('Opgaver');

    await this.loadDepartments();
    await this.loadSchoolTerms();
    await this.loadProjects();

    this.applyGlobalSchoolTermSelection();
  },
  methods: {
    ...mapActions(['updatePageTitle']),
    ...mapActions('projectsStore', ['loadProjects', 'setFilterProperty', 'clearFilterProperty']),
    ...mapActions('departments', ['loadDepartments']),
    ...mapActions('schoolTermStore', ['loadSchoolTerms']),
    onRowClick(row: ProjectsDto) {
      this.$router.push(`/project/${row.id}`);
    },
    applyGlobalSchoolTermSelection() {
      const globalSchoolTerm = this.schoolTerms.find((q:SchoolTermDto) => q.id === this.globalSchoolTermSelection);
      if (globalSchoolTerm) {
        this.setFilterProperty({schoolTerms: globalSchoolTerm.id});
        this.filterSchoolTerms = globalSchoolTerm;
      }
    }

  },
  computed: {
    ...mapGetters(['globalSchoolTermSelection']),
    ...mapGetters('projectsStore', ['projectsApiState', 'projects']),
    ...mapGetters('departments', {departments: 'departments', isLoadingDepartments: 'isLoading'}),
    ...mapGetters('schoolTermStore', ['schoolTerms', 'schoolTermApiState']),
    isLoading(): Boolean {
      return this.projectsApiState === APISTATE.LOADING;
    },
    isLoadingSchoolTerms(): Boolean {
      return this.schoolTermApiState === APISTATE.LOADING;
    }
  }
})
